import request from './request'

// 获取用户基本信息
export interface BasicDataDto {
  greeting: string
  join_days: number
  current_points: number
  notice: {
    title: string
    content: string
  }
  person: {
    active: number
    monthCompare: number
  }
  service: {
    avatar: string
    mobile: string
    name: string
  }
  wallet: {
    balance: number
    warning: string,
    is_direct: boolean
  }
}

export function getBasicData (): Promise<BasicDataDto> {
    return request.get('/home/data')
}

export interface CommonlyFileDto {
  name: string
  type: string
  url: string
}

export interface TimeInfo {
  img: string
  label: string
}

export interface RiskAnalysisDto {
  rank: string
  score: number
  report_num: number
  report_money: number
  pay_ratio: number
  premium_ratio: number
  advise: string[]
}

export interface WorkersDataDto {
  active_num: number
  average_age: number
  male_num: number
  female_num: number
  male_65_num: number
  female_65_num: number
  age_section: {
    name: string
    value: number
  }[]
}

export function getCommonlyFile (): Promise<CommonlyFileDto[]> {
    return request.get('/home/files')
}

// 获取活动信息
export function getNewActivity (params: {
  source: 'pc'
}): Promise<{ image: string; url: string }> {
    return request.get('/home/popup', {
        params
    })
}

// 获取活动信息
export function noReadMessage (): Promise<{ data: number }> {
    return request.get('/home/noReadMessage')
}

export function getRiskAnalysisData (): Promise<RiskAnalysisDto> {
    return request.get('/client/riskAnalysis')
}

export function workersAnlysis (): Promise<WorkersDataDto> {
    return request.get('/statistics.data/employeeAnalysis')
}
