import request, { RequestConfigDto } from './request'
import { ClientUserDto, QueryPageDto } from './dto'
import { number } from 'echarts'
// 团队动态
export interface TeamMessageDto {
    clientUser: ClientUserDto
    client_user_id: string
    content: string
    create_time: string
    id: number
    source: string
}
export function getTeamMessage (): Promise<TeamMessageDto[]> {
    return request.get('/client.log/list')
}

export interface MessageDto {
    content: string
    create_time: string
    icon: string
    id: number
    status: number
    title: string
    out_order_id:string
}
export function getMessage (
    params: QueryPageDto,
    config = {} as RequestConfigDto
): Promise<{
    data: MessageDto[]
    total: number
}> {
    config.params = params
    return request.get('/message/list', config)
}

export function getUnReadMessage (): Promise<number> {
    return request.get('/message/unreadNum')
}
